import { DocumentType, Expression, Language, Manifestation, Title, Work } from "@biblioteksentralen/cordata";
import { sift, unique } from "radash";
import { sortByMultiple } from "../../utils/sort";
import { collectionExpressionEntryHasWork } from "./collections";
import { isValidCoverImage } from "./coverImage";
import { isHoldingRepresentedInIsilCodes } from "./items";
import {
  CordataLanguageCode,
  LanguagesSignature,
  createLanguageCodeOrder,
  getSortedLanguagesListIndex,
  handleLanguages,
  languageListsEqual,
  sortedCordataLanguageCodes,
} from "./languages";
import { havePartTitles, haveSamePartTitlesSctructure, isbdFormattedTitle } from "./titles";
import { getDocumentTypeLabel } from "./documentTypes";

export const getDocumentTypes = ({ expressions }: Pick<Work, "expressions">): DocumentType[] =>
  unique(
    expressions.flatMap(({ manifestations = [], aggregateManifestations = [] }) =>
      sift([...manifestations, ...aggregateManifestations].flatMap(({ documentType }) => documentType))
    ),
    getDocumentTypeLabel
  );

const defaultFormatOrder = ["Bok", "E-bok", "Lydbok", "E-lydbok"];

export const getFormatOrderIndex = (
  format: DocumentType["format"] | undefined,
  preferredFormatOrder = defaultFormatOrder
): number =>
  format && defaultFormatOrder.includes(format) ? preferredFormatOrder.indexOf(format) : preferredFormatOrder.length;

type Representation = {
  representativeLanguages?: LanguagesSignature;
  representativeFormat?: DocumentType["format"];
};

export type WorkToBeRepresented = Work & Representation;

type ExpressionSummary<T extends Expression> = Pick<T, "languages" | "collections" | "contributors" | "title">;

export type SortableManifestation = Manifestation & {
  isAggregateManifestation?: boolean;
  expression: ExpressionSummary<Expression>;
};

export const getSortableManifestation = (
  manifestation: Manifestation,
  expression: ExpressionSummary<Expression>,
  preferredLanguageCodeOrder?: readonly CordataLanguageCode[]
): SortableManifestation => ({
  ...manifestation,
  collections: manifestation.collections.filter(collectionExpressionEntryHasWork),
  expression: {
    ...expression,
    languages: handleLanguages(preferredLanguageCodeOrder)(expression.languages),
    collections: expression.collections.filter(collectionExpressionEntryHasWork),
  },
});

const markAsAggregate = (manifestation: Manifestation) => ({ ...manifestation, isAggregateManifestation: true });

const getSortableManifestationsFromWork = (
  { expressions }: Work,
  preferredLanguageCodeOrder?: readonly CordataLanguageCode[]
): SortableManifestation[] =>
  unique(
    expressions.flatMap(({ manifestations = [], aggregateManifestations = [], ...expression }) =>
      [...manifestations, ...aggregateManifestations.map(markAsAggregate)].map((manifestation) =>
        getSortableManifestation(manifestation, expression, preferredLanguageCodeOrder)
      )
    ),
    ({ id }) => id
  );

export type ManifestationSorter = (items: SortableManifestation[]) => SortableManifestation[];

export const sortManifestationsByRelevance =
  (
    workTitle: Title,
    preferredLanguageCodeOrder: readonly CordataLanguageCode[] = sortedCordataLanguageCodes,
    preferredFormatOrder: DocumentType["format"][]
  ): ManifestationSorter =>
  (items) =>
    sortByMultiple(
      items,
      ({ expression }) => getSortedLanguagesListIndex(expression.languages, preferredLanguageCodeOrder),
      ({ documentType }) => getFormatOrderIndex(documentType?.format, preferredFormatOrder),
      ({ isAggregateManifestation }) => (isAggregateManifestation ? 2 : 1),
      ({ title }) => (haveSamePartTitlesSctructure(workTitle, title) ? 1 : 2),
      ({ publicationYear }) => (isNaN(Number(publicationYear)) ? 0 : -Number(publicationYear)),
      ({ coverImage }) => (isValidCoverImage(coverImage) ? 1 : 2)
    );

/**
 * Preferred in order: language then published date.
 * Choose an alternative cover image if necessary, in the same language
 *
 * TODO: Think about parts, series...
 * TODO: Unit test
 */
export const getRepresentativeManifestation = ({
  representativeLanguages,
  representativeFormat,
  ...work
}: WorkToBeRepresented): SortableManifestation | undefined => {
  const languageCodeOrder = createLanguageCodeOrder(representativeLanguages);
  const formatOrder = representativeFormat ? unique([representativeFormat, ...defaultFormatOrder]) : defaultFormatOrder;

  const sortManifestations = sortManifestationsByRelevance(work.title, languageCodeOrder, formatOrder);

  const sortedManifestations = sortManifestations(getSortableManifestationsFromWork(work, languageCodeOrder));
  const representativeManifestation = sortedManifestations?.[0];

  if (!representativeManifestation) return undefined;

  const representativeLanguage = representativeManifestation.expression.languages[0]?.code;

  const mostRelevantCoverImage = sortedManifestations
    .filter(({ expression }) => !!expression.languages[0] && expression.languages[0].code === representativeLanguage)
    // TODO: Also force same document type?
    .find(({ coverImage }) => isValidCoverImage(coverImage))?.coverImage;

  return { ...representativeManifestation, coverImage: mostRelevantCoverImage };
};

export const filterManifestationsOnHoldings = <ManifestationType extends Partial<Manifestation>>(
  manifestations: ManifestationType[],
  isilCodes: string[] | null
): ManifestationType[] =>
  manifestations
    .filter(({ holdings = [] }) => !!holdings?.some(isHoldingRepresentedInIsilCodes(isilCodes)))
    .map((manifestation) => ({
      ...manifestation,
      holdings: manifestation.holdings?.filter(isHoldingRepresentedInIsilCodes(isilCodes)),
    }));

export const getFormattedRepresentativeManifestationTitle = (
  work: Work,
  representativeManifestation: SortableManifestation | undefined
) => {
  const title = representativeManifestation?.isAggregateManifestation
    ? representativeManifestation?.expression.title
    : representativeManifestation?.title;

  return isbdFormattedTitle(title, havePartTitles(work.title));
};

export const getRelevantManifestations = (
  work: Work,
  languagesList: Language[] | undefined,
  documentType: DocumentType | undefined
) => {
  if (!languagesList || !documentType) return [];

  const sortableManifestations = getSortableManifestationsFromWork(work);

  return sortableManifestations.filter(
    (manifestation) =>
      languageListsEqual(manifestation.expression.languages, languagesList) &&
      manifestation.documentType?.code === documentType.code
  );
};

const getDefaultManifestationOrder = (work: Work) =>
  sortManifestationsByRelevance(work.title, [], [])(getSortableManifestationsFromWork(work));

export const getDefaultWorkImage = (work: Work) =>
  getDefaultManifestationOrder(work).find((manifestation) => manifestation.coverImage)?.coverImage;

export const getDefaultManifestation = (work: Work) => getDefaultManifestationOrder(work)?.[0];
