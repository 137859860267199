import { LanguageCode } from "@libry-content/localization";
import { TranslateSanityContent } from "../../utils/hooks/useTranslation";

export const contributorRoleLabels = [
  "annen rolle",
  "bearbeider",
  "beæret",
  "bokdesigner",
  "bokomslagsdesigner",
  "copyrightinnehaver",
  "designer",
  "dirigent",
  "eksamenssted",
  "forfatter",
  "fotograf",
  "idéskaper",
  "illustratør",
  "innleser",
  "instrumentalist",
  "intervjuer",
  "intervjuobjekt",
  "kolorist",
  "kommentator",
  "komponist",
  "konsulent",
  "kunstner",
  "kurator",
  "leder",
  "librettist",
  "manusforfatter",
  "medarbeider/bidragsyter",
  "medforfatter",
  "musikkarrangør",
  "opprinnelig forfatter",
  "oversetter",
  "prosjektleder",
  "redaktør",
  "regissør",
  "samlingsredaktør",
  "sanger",
  "sangtekstforfatter",
  "skuespiller",
  "utarbeider",
  "utgiver",
  "utøver",
  "vedtakende jurisdiksjon",
] as const;

export type ContributorRoleLabel = (typeof contributorRoleLabels)[number];

export const isContributorRoleLabel = (item: unknown): item is ContributorRoleLabel =>
  typeof item === "string" && (contributorRoleLabels as readonly string[]).includes(item);

// TODO: More?
const prioritizedContributorRoles: ContributorRoleLabel[] = [
  "forfatter",
  "kunstner",
  "oversetter",
  "regissør",
  "manusforfatter",
];

export const getContributorRoleSortIndex = (roleLabel: string) =>
  (prioritizedContributorRoles as string[]).includes(roleLabel)
    ? (prioritizedContributorRoles as string[]).indexOf(roleLabel)
    : prioritizedContributorRoles.length;

const roleLabelTranslations: Record<ContributorRoleLabel, Record<LanguageCode, string>> = {
  "annen rolle": { nb: "annen rolle", nn: "annan rolle" },
  bearbeider: { nb: "bearbeider", nn: "omarbeidar" },
  beæret: { nb: "beæret", nn: "æra" },
  bokdesigner: { nb: "bokdesigner", nn: "bokdesignar" },
  bokomslagsdesigner: { nb: "bokomslagsdesigner", nn: "bokomslagdesignar" },
  copyrightinnehaver: { nb: "copyrightinnehaver", nn: "copyrightinnehavar" },
  designer: { nb: "designer", nn: "designar" },
  dirigent: { nb: "dirigent", nn: "dirigent" },
  eksamenssted: { nb: "eksamenssted", nn: "eksamensstad" },
  forfatter: { nb: "forfatter", nn: "forfattar" },
  fotograf: { nb: "fotograf", nn: "fotograf" },
  idéskaper: { nb: "idéskaper", nn: "idéskapar" },
  illustratør: { nb: "illustratør", nn: "illustratør" },
  innleser: { nb: "innleser", nn: "innlesar" },
  instrumentalist: { nb: "instrumentalist", nn: "instrumentalist" },
  intervjuer: { nb: "intervjuer", nn: "intervjuar" },
  intervjuobjekt: { nb: "intervjuobjekt", nn: "intervjuobjekt" },
  kolorist: { nb: "kolorist", nn: "kolorist" },
  kommentator: { nb: "kommentator", nn: "kommentator" },
  komponist: { nb: "komponist", nn: "komponist" },
  konsulent: { nb: "konsulent", nn: "konsulent" },
  kunstner: { nb: "kunstner", nn: "kunstnar" },
  kurator: { nb: "kurator", nn: "kurator" },
  leder: { nb: "leder", nn: "leiar" },
  librettist: { nb: "librettist", nn: "librettist" },
  manusforfatter: { nb: "manusforfatter", nn: "manusforfattar" },
  "medarbeider/bidragsyter": { nb: "medarbeider/bidragsyter", nn: "medarbeidar/bidragsytar" },
  medforfatter: { nb: "medforfatter", nn: "medforfattar" },
  musikkarrangør: { nb: "musikkarrangør", nn: "musikkarrangør" },
  "opprinnelig forfatter": { nb: "opprinnelig forfatter", nn: "opphavleg forfattar" },
  oversetter: { nb: "oversetter", nn: "omsetjar" },
  prosjektleder: { nb: "prosjektleder", nn: "prosjektleiar" },
  redaktør: { nb: "redaktør", nn: "redaktør" },
  regissør: { nb: "regissør", nn: "regissør" },
  samlingsredaktør: { nb: "samlingsredaktør", nn: "samlingsredaktør" },
  sanger: { nb: "sanger", nn: "songar" },
  sangtekstforfatter: { nb: "sangtekstforfatter", nn: "songtekstforfattar" },
  skuespiller: { nb: "skuespiller", nn: "skodespelar" },
  utarbeider: { nb: "utarbeider", nn: "utarbeidar" },
  utgiver: { nb: "utgiver", nn: "utgjevar" },
  utøver: { nb: "utøver", nn: "utøvar" },
  "vedtakende jurisdiksjon": { nb: "vedtakende jurisdiksjon", nn: "vedtakande jurisdiksjon" },
};

export const getTranslatedRoleLabel = (ts: TranslateSanityContent, roleLabel: ContributorRoleLabel) =>
  ts(roleLabelTranslations[roleLabel]);

const roleLabelPluralTranslations: Partial<Record<ContributorRoleLabel, Record<LanguageCode, string>>> = {
  "annen rolle": { nb: "andre roller", nn: "andre rollar" },
  bearbeider: { nb: "bearbeidere", nn: "omarbeidarar" },
  beæret: { nb: "beærede", nn: "æra" },
  bokdesigner: { nb: "bokdesignere", nn: "bokdesignarar" },
  bokomslagsdesigner: { nb: "bokomslagsdesignere", nn: "bokomslagdesignarar" },
  copyrightinnehaver: { nb: "copyrightinnehavere", nn: "copyrightinnehavarar" },
  designer: { nb: "designere", nn: "designarar" },
  dirigent: { nb: "dirigenter", nn: "dirigentar" },
  eksamenssted: { nb: "eksamenssteder", nn: "eksamensstader" },
  forfatter: { nb: "forfattere", nn: "forfattarar" },
  fotograf: { nb: "fotografer", nn: "fotografar" },
  idéskaper: { nb: "idéskapere", nn: "idéskaparar" },
  illustratør: { nb: "illustratører", nn: "illustratørar" },
  innleser: { nb: "innlesere", nn: "innlesarar" },
  instrumentalist: { nb: "instrumentalister", nn: "instrumentalistar" },
  intervjuer: { nb: "intervjuere", nn: "intervjuarar" },
  intervjuobjekt: { nb: "intervjuobjekter", nn: "intervjuobjekt" },
  kolorist: { nb: "kolorister", nn: "koloristar" },
  kommentator: { nb: "kommentatorer", nn: "kommentatorar" },
  komponist: { nb: "komponister", nn: "komponistar" },
  konsulent: { nb: "konsulenter", nn: "konsulentar" },
  kunstner: { nb: "kunstnere", nn: "kunstnarar" },
  kurator: { nb: "kuratorer", nn: "kuratorar" },
  leder: { nb: "ledere", nn: "leiarar" },
  librettist: { nb: "librettister", nn: "librettistar" },
  manusforfatter: { nb: "manusforfattere", nn: "manusforfattarar" },
  "medarbeider/bidragsyter": { nb: "medarbeidere/bidragsytere", nn: "medarbeidarar/bidragsytarar" },
  medforfatter: { nb: "medforfattere", nn: "medforfattarar" },
  musikkarrangør: { nb: "musikkarrangører", nn: "musikkarrangørar" },
  "opprinnelig forfatter": { nb: "opprinnelige forfattere", nn: "opphavlege forfattarar" },
  oversetter: { nb: "oversettere", nn: "omsetjarar" },
  prosjektleder: { nb: "prosjektledere", nn: "prosjektleiarar" },
  redaktør: { nb: "redaktører", nn: "redaktørar" },
  regissør: { nb: "regissører", nn: "regissørar" },
  samlingsredaktør: { nb: "samlingsredaktører", nn: "samlingsredaktørar" },
  sanger: { nb: "sangere", nn: "songarar" },
  sangtekstforfatter: { nb: "sangtekstforfattere", nn: "songtekstforfattarar" },
  skuespiller: { nb: "skuespillere", nn: "skodespelarar" },
  utarbeider: { nb: "utarbeidere", nn: "utarbeidarar" },
  utgiver: { nb: "utgivere", nn: "utgjevarar" },
  utøver: { nb: "utøvere", nn: "utøvarar" },
  "vedtakende jurisdiksjon": { nb: "vedtakende jurisdiksjoner", nn: "vedtakande jurisdiksjonar" },
};

export const getTransformedRoleLabel = (
  ts: TranslateSanityContent,
  roleLabel: ContributorRoleLabel | undefined,
  plural: boolean = false
) => {
  if (plural)
    return roleLabel ? ts(roleLabelPluralTranslations[roleLabel] ?? roleLabelTranslations[roleLabel]) : undefined;
  return roleLabel ? ts(roleLabelTranslations[roleLabel] ?? roleLabelTranslations[roleLabel]) : undefined;
};
